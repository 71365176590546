<template>
  <sticky-stack-layout
    :offset="layoutOptions ? layoutOptions.offset : 105"
    :header-height="layoutOptions ? layoutOptions.headerHeight : 64"
    :footer-height="layoutOptions ? layoutOptions.footerHeight : 58"
  >
    <template v-slot:header>
      <v-card-title>{{ getTitle }}</v-card-title>
    </template>
    <div v-if="getSelectedChatRoom" class="py-2">
      <div
        v-for="(message, index) in getMessages"
        :key="index"
      >
        <chat-message
          :username="message.sender"
          :content="message.content"
          :avatar="message.avatar"
          :mine="isMine(message.source)"
          :source="message.source"
          :participant="getParticipant(message.source)"
          :same-source="sameSource(message.source, index)"
          :time="message.time"
        />
      </div>
    </div>
    <template v-slot:footer>
      <div
        class="grey lighten-3 d-flex pa-2"
        style="position: absolute; bottom: 0; width: 100%"
      >
        <typing-dots :active="typing" />

        <!-- Input field and send button here -->
        <v-textarea
          rounded
          outlined
          full-width
          hide-details
          v-model="message"
          label="Type a message..."
          class="textbox"
          ref="textbox"
          :rows="textboxLines"
          @input="adjustHeight()"
          dense
          :disabled="sending"
        >
          <template v-slot:append>
            <v-btn
              depressed
              icon
              @click="sendMessage()"
              :disabled="!message || !message.trim() || sending"
            >
              <v-icon large>mdi-message-text-fast</v-icon>
            </v-btn>
          </template>
        </v-textarea>
      </div>
    </template>
  </sticky-stack-layout>
</template>

<script>
import TypingDots from "@/common/components/TypingDots";
import StickyStackLayout from "@/common/layouts/StickyStackLayout";
import ChatMessage from "@/common/components/ChatMessage";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "ChatBox",
  components: { ChatMessage, TypingDots, StickyStackLayout },
  props: {
    room: String,
    roomType: String,
    participants: Array,
    audiance: String,
    layoutOptions: Object,

    // messages: {
    //   type: Array,
    //   default() {
    //     return [
    //       {
    //         sender: "User 1",
    //         text: "Hello...",
    //         mine: false,
    //         avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
    //       },
    //       {
    //         sender: "User 1",
    //         text: "Hey there! How's your day going?",
    //         mine: false,
    //         avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
    //       },
    //       {
    //         sender: "You",
    //         text: "Hello! My day is going well, thank you. How about yours? Hello! My day is going well, thank you. How about yours? Hello! My day is going well, thank you. How about yours? Hello! My day is going well, thank you. How about yours?",
    //         mine: true,
    //         avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
    //       },
    //       {
    //         sender: "You",
    //         text: "Hello! My day is going well, thank you. How about yours? Hello! My day is going well, thank you. How about yours? Hello! My day is going well, thank you. How about yours?",
    //         mine: true,
    //         avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
    //       },
    //       {
    //         sender: "User 1",
    //         text: "It's been a bit busy, but good overall. I've been working on a new project. How's your work going?",
    //         mine: false,
    //         avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
    //       },
    //       {
    //         sender: "You",
    //         text: "That sounds interesting! My work is going smoothly too. I'm excited about some new features we're adding to our app.",
    //         mine: true,
    //         avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
    //       },
    //       {
    //         sender: "User 1",
    //         text: "That's great to hear! Your team is doing some cool stuff. By the way, have you tried that new restaurant in town?",
    //         mine: false,
    //         avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
    //       },
    //       {
    //         sender: "You",
    //         text: "Yes, I went there last weekend. The food was amazing! We should go together sometime.",
    //         mine: true,
    //         avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
    //       },
    //       {
    //         sender: "User 1",
    //         text: "Definitely! Let's plan it for next week. I'll text you the details. Anyway, got to get back to work now. Talk to you later!",
    //         mine: false,
    //         avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
    //       },
    //       {
    //         sender: "You",
    //         text: "Sure, sounds good! Talk to you later. Have a productive day!",
    //         mine: true,
    //         avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
    //       },
    //     ];
    //   },
    // },
  },
  data() {
    return {
      message: "",
      sending: false,
      errorSending: false,
      typing: false,
      userUuid: this.$getUserInfo().uuid,
      textboxLines: 1,
    };
  },
  mounted() {
    console.log(this.layoutOptions);
    this.selectChatRoom(this.room);
    this.loadChatRoom(this.room);
  },
  beforeDestroy() {
    this.clearSelectedChatRoom();
  },
  computed: {
    ...mapGetters("chat", ["getSelectedChatRoom", "getChatRoom"]),

    getTitle() {
      const aud = this.audiance;
      return this.$t("chat.talkTo" + aud, { customerName: "Abc" });
    },
    getMessages() {
      return this.getSelectedChatRoom.messages.sort((a, b) => moment(a.time) - moment(b.time));
    },
    getRoom() {
      this.getChatRoom(this.room);
    },
  },
  methods: {
    ...mapActions("chat", [
      "loadChatRoom",
      "loadChatMessages",
      "selectChatRoom",
      "clearSelectedChatRoom",
      "sendChatMessage",
      "startChatRoom",
      "closeChatRoom",
      "inviteChatParticipants",
      "deleteChatMessage",
    ]),

    sendMessage() {
      this.errorSending = false;
      if (this.message && this.message.trim()) {
        this.sending = true;
        if (this.getSelectedChatRoom) {
          const params = {
            content: this.message,
            source: this.userUuid,
            room: {
              uuid: this.room,
              roomType: this.roomType,
            },
          };

          this.sendChatMessage(params)
            .then(() => {
              this.sending = false;
              this.message = "";
            })
            .catch(() => {
              this.sending = false;
              this.errorSending = true;
            });
        } else {
          const params = {
            uuid: this.room,
            roomType: this.roomType,
            participants: this.participants,
            source: this.userUuid,
            content: this.message,
          };

          this.startChatRoom(params)
            .then(() => {
              this.sending = false;
              this.message = "";
            })
            .catch(() => {
              this.sending = false;
              this.errorSending = true;
            });
        }
      }
    },
    deleteMessage(id) {
      this.sendChatMessage({ room: this.room, id });
    },

    getColor(mine) {
      return mine ? "secondary lighten-1" : "grey lighten-3";
    },
    sameSource(source, index) {
      return (
        index > 0 &&
        this.notEmptyArray(this.getMessages) &&
        this.getMessages[index - 1].source === source
      );
    },
    isMine(source) {
      return source === this.userUuid;
    },
    getParticipant(source) {
      return this.getSelectedChatRoom.participants.find((f) => f.uuid === source);
    },
    adjustHeight() {
      const lines = this.message.split(/\n/); // \r|\r\n|\n
      this.textboxLines = lines.length > 2 ? 3 : lines.length;
      this.footerHeight = 30 + this.textboxLines * 26;
    },
  },
};
</script>

<style>
.textbox textarea {
  resize: none !important;
  max-height: 84px;
  padding: 0;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  overflow-y: auto;
}
.v-input__append-inner {
  width: 25px;
  margin-top: 3px !important;
  margin-left: 10px !important;
}
</style>
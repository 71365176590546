<template>
  <div style="height: 250px; width: 100%; z-index: 0; position: relative">
    <l-map
      ref="map"
      :options="mapOptions"
      :max-bounds="bounds ? getBounds : undefined"
      :center="currentCenter"
      :zoom="currentZoom"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
      @ready="setMap"
      height="100%"
    >
      <l-control-fullscreen position="topleft" />
      <l-tile-layer :url="url" :attribution="attribution" />

      <l-marker
        v-for="(marker, i) in markers"
        :key="i"
        :lat-lng="getPoint(marker)"
      >
        <l-icon :icon-size="[32, 32]">
          <div
            :class="marker.class"
            :style="`border: 2px solid ${marker.color} !important;`"
          >
            <v-icon
              v-text="marker.icon"
              :color="marker.color"
              class="icon-position"
            />
            <div
              v-if="marker.selected"
              style="position: absolute; top: -16px; left: 2px"
            >
              <v-icon
                x-large
                color="red"
                style="font-size: 48px; left: -12px; top: 6px"
                >mdi-selection-ellipse-arrow-inside</v-icon
              >
            </div>
          </div>
        </l-icon>

        <l-tooltip
          v-if="marker.status"
          :options="{ permanent: marker.fixedTooltip, interactive: true }"
          @click="marker.click"
        >
          {{ marker.status }}
        </l-tooltip>
      </l-marker>

      <l-control position="bottomright">
        <slot name="bottomright"></slot>
      </l-control>
      <l-control position="bottomleft">
        <slot name="bottomleft"></slot>
      </l-control>
      <l-control position="topright">
        <slot name="topright"></slot>
      </l-control>
    </l-map>
    <unclickable right bottom />
  </div>
</template>

<script>
import L from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LTooltip,
  LIcon,
  LPolygon,
  LControl,
} from "vue2-leaflet";
import Unclickable from "@/common/components/Unclickable";
import "leaflet/dist/images/marker-shadow.png";
import LControlFullscreen from "vue2-leaflet-fullscreen";

export default {
  name: "MarkersMap",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LIcon,
    LPolygon,
    Unclickable,
    LControlFullscreen,
    LControl
  },
  model: {
    prop: "markers",
    event: "input",
  },
  props: {
    markers: Array,
    geofence: Array,
    lastUpdate: Date,
    showCurrentButton: Boolean,
    customButtons: Array,
    bounds: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      polygon: null,
      // zoom: 15,
      initHeight: null,
      // locations: [],
      map: null,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      // url: "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
      attribution: "&copy; OSM",
      currentZoom: this.geofence ? 13.5 : undefined,
      currentCenter: this.getCenter,
      mapOptions: {
        attributionControl: false,
        zoomSnap: 1,
        minZoom: 12,
        maxZoom: this.bounds ? 20 : undefined,
      },
    };
  },
  watch: {
    geofence(n) {
      this.setMap();
    },
    currentCenter(n) {
      const driverMarker = this.markers.find((f) => f.type === "DRIVER");
      console.log("driverMarker", driverMarker);
      if (driverMarker) {
        driverMarker.latitude = n.latitude;
        driverMarker.longitude = n.longitude;
      }
    },
  },
  computed: {
    getCenter() {
      if (this.notEmptyArray(this.markers)) {
        const m = this.markers[this.markers.length - 1];
        return L.latLng(m.latitude, m.longitude);
      }

      return L.latLng(12.7533427, 44.8926315);
    },
    getBounds() {
      if (this.notEmptyArray(this.geofence)) {
        var rectangle = L.rectangle(this.geofence);
        var sw = rectangle.getBounds().getSouthWest();
        var ne = rectangle.getBounds().getNorthEast();
        var b = L.latLngBounds([
          [sw.lat, sw.lng],
          [ne.lat, ne.lng],
        ]);

        return b.pad(0.5);
      }
      return null;
    },
  },
  methods: {
    getPoint(marker) {
      return L.latLng(marker.latitude, marker.longitude);
    },
    async panToCurrent() {
      this.$currentPosition().then((pos) => {
        const { coords } = pos;
        const { latitude, longitude } = coords;
        this.currentCenter = this.getPoint({ latitude, longitude });
      });
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    async setMap() {
      await this.$nextTick();

      this.map = this.$refs.map.mapObject;
      console.log(this.markers);

      if (this.notEmptyArray(this.geofence)) {
        if (this.polygon) {
          this.map.removeLayer(this.polygon);
        }

        this.polygon = L.polygon(this.geofence, {
          color: "#54fd94",
          weight: 2,
          dashArray: "3 5",
        }).addTo(this.map);

        // this.map.fitBounds(this.getBounds, { padding: [20, 20]});
      }

      if (this.showCurrentButton) {
        const clickHandler = this.panToCurrent;

        var PanToCurrentButton = L.Control.extend({
          options: {
            position: "topleft",
          },

          onAdd: function (map) {
            var container = L.DomUtil.create(
              "a",
              "leaflet-bar leaflet-control leaflet-control-custom v-icon notranslate mdi mdi-target theme--light"
            );

            container.style.backgroundColor = "white";
            container.style.color = "#404040";
            container.style.backgroundSize = "33px 33px";
            container.style.width = "34px";
            container.style.height = "34px";

            container.onclick = clickHandler;

            return container;
          },
        });
        this.map.addControl(new PanToCurrentButton());
      }

      if (this.notEmptyArray(this.customButtons)) {
        for (let i = 0; i < this.customButtons.length; i++) {
          const btn = this.customButtons[i];

          var CustomButton = L.Control.extend({
            options: {
              position: "topleft",
            },

            onAdd: function (map) {
              var container = L.DomUtil.create(
                "a",
                "leaflet-bar leaflet-control leaflet-control-custom v-icon notranslate theme--light mdi " +
                  btn.icon
              );

              container.style.backgroundColor = "white";
              container.style.backgroundSize = "34px 34px";
              container.style.width = "34px";
              container.style.height = "34px";

              container.onclick = function () {
                btn.click();
              };

              return container;
            },
          });
          this.map.addControl(new CustomButton());
        }
      }

      setTimeout(() => {
        this.currentCenter = this.getCenter;
        this.map.invalidateSize();
      }, 200);

      // this.map.maxBounds = () => this.getBounds;
      // this.locations = this.markers;
      // if (this.notEmptyArray(this.locations) && this.locations.length === 3) {
      //   var bound = L.latLngBounds([
      //     this.getPoint(this.locations[0]),
      //     this.getPoint(this.locations[1]),
      //     this.getPoint(this.locations[2]),
      //   ]);
      //   this.map.fitBounds(bound, { padding: L.point(30, 30) });
      // }
    },
  },
};
</script>

<style>
.pin {
  position: absolute;
  border-radius: 50% 50% 50% 0;
  border: 2px solid #fff;
  width: 32px;
  height: 32px;
  transform: rotate(-45deg);
  margin-top: -16px;
}

.pin2 {
  position: absolute;
  border-radius: 50% 50% 0 50%;
  border: 2px solid #00000065 !important;
  border-color: #00000065 !important;
  width: 32px;
  height: 32px;
  margin-top: -15px;
  margin-left: -15px;
}

.circle {
  position: absolute;
  border-radius: 50% 50% 50% 50%;
  border: 1px solid rgb(196, 199, 20);
  width: 32px;
  height: 32px;
}

.pin .icon-position {
  transform: rotate(45deg);
  margin-top: 1px;
  margin-left: 3px;
}

.pin2 .icon-position,
.circle .icon-position {
  margin-top: 2px;
  margin-left: 2px;
}

.selected-map-marker {
  transform: rotate(-45deg);
  position: absolute;
  border-radius: 50% 50% 50% 50%;
  border: 1px dotted #f1292f;
  width: 52px;
  height: 52px;
  animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>

<template>
  <div>
    <v-alert v-if="notEmptyArray(items) && notEmptyArray(itemsFeedback)" class="body-2 py-1 px-2  black--text" icon="mdi-alert-outline" type="warning" dense>
      Some items are not available now, or the available quanitity is not sufficient. Please review the messages associated with items.
    </v-alert>

    <v-simple-table id="order-items" dense class="table-nox-overflow">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="px-1">Name</th>
            <th class="text-center">Qty</th>
            <th class="text-right">Price</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr @click="$emit('item:click', item)" v-for="(item, i) in getItems" :class="scanStatusColor(item)" :key="i">
            <td :style="getSelectedUuid === item.productUuid ? 'border-left: 4px solid #3c2366;' : ''">
              <div class="d-flex">
                <div>
                  <v-icon v-if="notScanned(item)" small color="grey lighter-3">mdi-circle</v-icon>
                  <v-icon v-if="scanNotCompleted(item)" small color="warning">mdi-checkbox-blank-circle</v-icon>
                  <v-icon v-if="scanComplete(item)" small color="success">mdi-checkbox-marked-circle</v-icon>
                  <v-icon v-if="scanOverflow(item)" small color="error">mdi-alert</v-icon>
                  <!-- for order scan -->
                </div>
                <div style="padding: 1px 0px 0px 2px;">{{ getLocale.lang === "ar" ? item.nameAr : item.name }}</div>
              </div>
              <div v-if="!!item.itemsFeedback" class="red--text">
                {{
                  $t(item.itemsFeedback.message, {
                    available: item.itemsFeedback.attributes.available,
                  })
                }}
              </div>
            </td>
            <td v-if="!editable" class="text-center"><span class="caption">{{ getScanQuantity(orderUuid, item.productUuid) }}</span>/<span class="body-2">{{ item.quantity }}</span></td>
            <td v-if="editable" class="text-right">
              <cart-buttons :item="item" :warning="!!item.itemsFeedback" />
            </td>
            <td :class="`text-right ${!!item.itemsFeedback ? 'red--text' : ''}`" style="min-width: 90px">
              {{ currency + " " + item.unitPrice * item.quantity }}
            </td>
            <td>
              <v-menu bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-dots-vertical</v-icon>
                </template>

                <v-list dense class="pa-0">
                  <v-list-item @click="itemReset(item)">
                    <v-list-item-title>Reset</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import CartButtons from "@/views/orders/CartButtons";
import { mapGetters } from 'vuex';

export default {
  name: "OrderScans",
  components: { CartButtons },
  model: {
    prop: "items",
    event: "input",
  },
  props: {
    orderUuid: String,
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    scans: {
      type: Array,
      default() {
        return [];
      },
    },
    itemsFeedback: {
      type: Array,
      default() {
        return [];
      },
    },
    editable: Boolean,
    currency: {
      type: String,
      default() {
        return "YE";
      },
    },
  },
  computed: {
    ...mapGetters("counters", ["getScanQuantity", "getActiveScan"]),

    getItems() {
      let items = [];
      if (this.notEmptyArray(this.items) && this.notEmptyArray(this.itemsFeedback)) {
        items = this.items.map((m) => ({
          ...m,
          itemsFeedback: this.itemsFeedback.find((f) => f.resourceId === m.id),
        }));
      } else {
        items = [...this.items];
      }

      console.log(items)

      return items;
    },
    getSelectedUuid() {
      const activeScan = this.getActiveScan(this.orderUuid);
      return activeScan ? activeScan.productUuid : null;
    },
  },
  methods:{
    scanTimes(uuid) {
      const item = this.scans.find((f) => f.productUuid === uuid);
      return item ? item.scans : 0;
    },
    scanComplete(item) {
      return item && this.scanTimes(item.productUuid) === item.quantity;
    },
    notScanned(item) {
      return item && this.scanTimes(item.productUuid) === 0;
    },
    scanOverflow(item) {
      return item && this.scanTimes(item.productUuid) > item.quantity;
    },
    scanNotCompleted(item) {
      return item && this.scanTimes(item.productUuid) > 0 && this.scanTimes(item.productUuid) < item.quantity;
    },
    scanStatusColor(item) {
      return this.scanComplete(item) ?
        'green lighten-5':
        this.scanNotCompleted(item) ?
          'yellow lighten-4':
          this.scanOverflow(item) ?
            'red lighten-5':
            '';
    },
    itemReset(item) {
      this.$emit("item:reset", item);
    },
  },
};
</script>

<style>
  #order-items th, #order-items td {
    padding: 0 2px;
  }

  #order-items th:not(:last-child), #order-items td:not(:last-child) {
    border-right: 1px dashed #eee;
  }
</style>
<template>
  <v-form class="pa-2">
    <v-text-field
      ref="barcodeInput"
      v-model="barcode"
      label="Barcode"
      outlined
      dense
      hide-details
      style="z-index: 10000"
      @blur="checkScanMode"
      @keydown.enter="processBarcode"
    />
  </v-form>
</template>

<script>
export default {
  name: "ItemScanner",
  data() {
    return {
      barcode: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.barcodeInput.focus();
    });
  },
  methods: {
    checkScanMode() {
      setTimeout(() => {
        this.$refs.barcodeInput.focus();
      }, 100);
    },
    processBarcode() {
      if(this.barcode) {
        this.$emit("scanned", this.barcode);
        this.barcode = null;
      }
    },
  },
};
</script>
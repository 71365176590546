<template>
  <v-card flat>
    <v-skeleton-loader v-if="!order" type="actions, card, table" />

    <v-tabs v-if="order" v-model="tabs" background-color="grey lighten-3" show-arrows :style="dispatchMode ? 'margin-left: 36px !important; width: 405px;' : ''">
      <v-tabs-slider></v-tabs-slider>

      <v-tab href="#tab-receipt" class="primary--text" style="min-width: 60px">
        <v-icon>mdi-cart</v-icon>
      </v-tab>

      <v-tab href="#tab-map" class="primary--text" style="min-width: 60px">
        <v-icon>mdi-map-marker</v-icon>
      </v-tab>

      <v-tab
        v-if="driver"
        href="#tab-driver"
        class="primary--text"
        style="min-width: 60px"
        @click="$$router.replace({ query: { tab: 'driver' } })"
      >
        <v-icon>mdi-truck-delivery</v-icon>
      </v-tab>

      <v-tab
        v-if="support"
        href="#tab-support"
        class="primary--text"
        style="min-width: 60px"
      >
        <v-icon>mdi-headset</v-icon>
      </v-tab>

      <v-tab
        v-if="history"
        href="#tab-history"
        class="primary--text"
        style="min-width: 60px"
        @click="$$router.replace({ query: { tab: 'history' } })"
      >
        <v-icon>mdi-history</v-icon>
      </v-tab>

      <v-divider vertical />

      <v-tab
        v-if="customer"
        href="#tab-customer"
        class="primary--text"
        style="min-width: 60px"
        @click="$$router.replace({ query: { tab: 'customer' } })"
      >
        <v-icon>mdi-account</v-icon>
      </v-tab>

      <v-tab
        v-if="customer || driver"
        href="#tab-customer-driver-chat"
        class="primary--text"
        style="min-width: 60px"
        @click="$$router.replace({ query: { tab: 'customer-driver-chat' } })"
      >
        <v-icon>mdi-tooltip-account</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-if="order" v-model="tabs">
      <v-tab-item value="tab-receipt">
        <sticky-stack-layout
          :offset="scanMode ? 120 : 60"
          :header-height="48"
          :footer-height="dispatchMode ? scanMode ? 213 : 190 : 226"
          :style="getViewHeightStyle(heightOffset)"
        >
        <!-- :layout-options="{offset: dispatchMode ? 49 : 105, headerHeight: 64, footerHeight: 58}" -->
          <template v-slot:header>
            <v-toolbar flat short dense color="grey lighten-5 toolbar-compact">
              <v-btn
                v-if="audiance === 'Manager'"
                :disabled="orderScanComplete"
                max-width="40"
                @click="toggleScanMode"
                depressed
                color="primary"
              >
                <v-icon large>mdi-barcode-scan</v-icon>
              </v-btn>
              <v-divider v-if="audiance === 'Manager'" vertical inset class="mx-1" />
              <v-btn
                v-if="audiance === 'Manager'"
                :disabled="!orderScanComplete"
                max-width="40"
                @click="confirmOrderScan"
                depressed
                color="success"
              >
                <v-icon large>mdi-playlist-check</v-icon>
              </v-btn>
              <v-btn
                v-if="audiance === 'Dispatcher'"
                :disabled="order.activeTrackStatus !== 'DELIVERED'"
                max-width="40"
                @click="confirmCashCollection"
                depressed
                color="success"
              >
                <v-icon large>mdi-cash-register</v-icon>
              </v-btn>
              <v-divider v-if="audiance === 'Dispatcher'" vertical inset class="mx-1" />
              <v-btn
                v-if="audiance === 'Dispatcher'"
                :disabled="order.activeTrackStatus !== 'RETURNING' || orderScanComplete"
                max-width="40"
                @click="toggleScanMode"
                depressed
                color="cyan darken-4"
                :dark="order.activeTrackStatus === 'RETURNING' && !orderScanComplete"
              >
                <v-icon large>mdi-arrow-u-left-top</v-icon>
              </v-btn>
              <v-btn
                v-if="audiance === 'Dispatcher'"
                :disabled="order.activeTrackStatus !== 'RETURNING' || !orderScanComplete"
                max-width="40"
                @click="confirmOrderReturn"
                depressed
                color="success"
                :dark="order.activeTrackStatus === 'RETURNING' && orderScanComplete"
              >
                <v-icon large>mdi-playlist-check</v-icon>
              </v-btn>
              <v-spacer />
              <v-btn icon small tile depressed>
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
              <v-divider vertical inset />
              <v-btn icon small tile depressed>
                <v-icon>mdi-share-variant</v-icon>
              </v-btn>
              <v-divider vertical inset />
              <v-btn icon small tile depressed>
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </v-toolbar>

            <item-scanner
              @scanned="itemScanned"
              v-if="scanMode && !orderScanComplete"
            />
          </template>

          <section-header
            class="px-2"
            hide-divider
            title="Items List"
          />
          
          <div>
            <order-items
              v-if="!dispatchMode"
              v-model="getItems"
              :currency="getCurrency"
            />

            <order-scans
              v-else
              v-model="getItems"
              :order-uuid="order.uuid"
              :scans="getScannedItems(order.uuid)"
              :currency="getCurrency"
              @item:reset="itemReset"
              @item:click="itemSelected"
              style="margin: auto -6px;"
            />
          </div>

          <template v-slot:footer>
            <section-header class="px-2 mt-0 white" title="Summary" />
            <order-summary
              v-model="order"
              :currency="getCurrency"
              v-if="!scanMode"
            />
            <v-card flat v-if="scanMode && getOrderItem">
              <v-img :src="selectedItemImage" height="150" contain />
              <div class="pa-2 text-center">
                {{ getOrderItem.name }}
                <v-chip class="ma-1" :color="scanStatusColor">
                  <v-icon v-if="notScanned" small color="grey lighter-3">mdi-circle</v-icon>
                  <v-icon v-if="scanNotCompleted" small color="warning">mdi-checkbox-blank-circle</v-icon>
                  <v-icon v-if="scanComplete" small color="success">mdi-checkbox-marked-circle</v-icon>
                  <v-icon v-if="scanOverflow" small color="error">mdi-alert</v-icon>

                  Scans: {{ scanTimes }} / {{ getOrderItem.quantity }}</v-chip
                >
              </div>
            </v-card>
          </template>
        </sticky-stack-layout>
      </v-tab-item>

      <v-tab-item value="tab-map">
        <v-card flat>
          <markers-map
            :markers="getMarkers(order)"
            :geofence="getAosCoordinates"
            :style="getViewHeightStyle(heightOffset)"
            show-current-button
          >
            <!-- <template slot="topright">
              <v-btn
                large
                depressed
                color="primary"
                @click="$$router.replace({ query: { tab: 'driver' } })"
              >
                <v-icon>mdi-phone-dial</v-icon> {{ order.customerPhone }}
              </v-btn>
            </template> -->
          </markers-map>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="customer" value="tab-customer">
        <v-card flat>
          <v-card-title>
            <v-avatar size="56">
              <img alt="customer" :src="getCustomerAvatar" />
            </v-avatar>
            <p class="mx-1 mb-0">{{ order.customerName }}</p>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col><v-icon>mdi-map-marker</v-icon> Address:</v-col>
              <v-col>{{ order.customerAddress }}</v-col>
            </v-row>
            <v-row>
              <v-col><v-icon>mdi-phone</v-icon> Phone:</v-col>
              <v-col class="text-right">{{ order.customerPhone }}</v-col>
            </v-row>
            <v-divider class="my-3" />
            <v-card-actions>
              <v-btn depressed @click="callCustomer(order.customerUuid)">
                <v-icon>mdi-phone-dial</v-icon>
              </v-btn>
              <v-btn depressed @click="beepAlert(order.customerUuid)">
                <v-icon>mdi-bullhorn</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item v-if="driver" value="tab-driver">
        <v-card flat>
          <v-card-title>
            <v-avatar size="56">
              <img alt="customer" :src="getDriverAvatar" />
            </v-avatar>
            <p class="mx-1 mb-0">{{ order.driverName }}</p>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col><v-icon>mdi-phone</v-icon> Phone:</v-col>
              <v-col class="text-right">{{ order.driverPhone }}</v-col>
            </v-row>
            <v-divider class="my-3" />
            <v-card-actions>
              <v-btn depressed>
                <v-icon>mdi-phone-dial</v-icon>
              </v-btn>
              <v-btn depressed @click="beepAlert(order.driverUuid)">
                <v-icon>mdi-bullhorn</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item v-if="history" value="tab-history">
        <v-timeline align-top dense>
          <v-timeline-item
            :color="getStatusTypeColor(track.status)"
            small
            v-for="(track, i) in order.tracks"
            :key="i"
          >
            <v-row class="pt-1">
              <v-col cols="3">
                <strong>{{ track.status }}</strong>
                <p>{{ formatTime(track.time) }}</p>
              </v-col>
              <!-- <v-col>
                <strong>New Icon</strong>
                <div class="caption">
                  Mobile App
                </div>
              </v-col> -->
            </v-row>
          </v-timeline-item>

          <!-- <v-timeline-item color="teal lighten-3" small>
            <v-row class="pt-1">
              <v-col cols="3">
                <strong>3-4pm</strong>
              </v-col>
              <v-col>
                <strong>Design Stand Up</strong>
                <div class="caption mb-2">
                  Hangouts
                </div>
                <v-avatar>
                  <v-img
                    src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairFrida&accessoriesType=Kurt&hairColor=Red&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=GraphicShirt&clotheColor=Gray01&graphicType=Skull&eyeType=Wink&eyebrowType=RaisedExcitedNatural&mouthType=Disbelief&skinColor=Brown"
                  ></v-img>
                </v-avatar>
                <v-avatar>
                  <v-img
                    src="https://avataaars.io/?avatarStyle=Circle&topType=ShortHairFrizzle&accessoriesType=Prescription02&hairColor=Black&facialHairType=MoustacheMagnum&facialHairColor=BrownDark&clotheType=BlazerSweater&clotheColor=Black&eyeType=Default&eyebrowType=FlatNatural&mouthType=Default&skinColor=Tanned"
                  ></v-img>
                </v-avatar>
                <v-avatar>
                  <v-img
                    src="https://avataaars.io/?avatarStyle=Circle&topType=LongHairMiaWallace&accessoriesType=Sunglasses&hairColor=BlondeGolden&facialHairType=Blank&clotheType=BlazerSweater&eyeType=Surprised&eyebrowType=RaisedExcited&mouthType=Smile&skinColor=Pale"
                  ></v-img>
                </v-avatar>
              </v-col>
            </v-row>
          </v-timeline-item> -->
        </v-timeline>
      </v-tab-item>

      <v-tab-item v-if="support" value="tab-support"> </v-tab-item>

      <v-tab-item v-if="customer || driver" value="tab-customer-driver-chat">
        <chat-box
          :style="getViewHeightStyle(heightOffset)"
          :room="getRoomUuid(order.uuid, 'dlvr')"
          :room-type="roomTypes.ROOM_DELIVERY"
          :participants="[order.customerUuid, order.driverUuid]"
          :audiance="audiance"
          :layout-options="{offset: dispatchMode ? 49 : 105, headerHeight: 64, footerHeight: 58}"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import SectionHeader from "@/common/components/SectionHeader";
import ChatBox from "@/common/components/ChatBox";
import OrderItems from "@/views/orders/OrderItems";
import OrderScans from "@/views/orders/OrderScans";
import OrderSummary from "@/views/orders/OrderSummary";
import mixin from "@/views/orders/orders.mixin";
import { mapActions, mapGetters } from "vuex";
import StickyStackLayout from "@/common/layouts/StickyStackLayout";
import { ROOM_DELIVERY } from "@/store/actions/type.chat";
import MarkersMap from "@/common/components/MarkersMap";
import ItemScanner from "./ItemScanner";
import AdminConfirmDialog from "@/views/admin/orders/AdminConfirmDialog";

export default {
  name: "OrderDetails",
  mixins: [mixin],
  components: {
    SectionHeader,
    OrderItems,
    OrderScans,
    OrderSummary,
    ChatBox,
    StickyStackLayout,
    MarkersMap,
    ItemScanner,
    AdminConfirmDialog,
  },
  model: {
    prop: "order",
    event: "input",
  },
  props: {
    order: Object,
    customer: Boolean,
    driver: Boolean,
    history: Boolean,
    support: Boolean,
    audiance: {
      type: String,
      validator: function (value) {
        return ["Customer", "Driver", "Support", "Manager", "Dispatcher"].includes(value);
      },
    },
    heightOffset: {
      type: Number,
      default() {
        return 104; // the height margin for the the entire stack
      },
    },
    offset: {
      type: Number,
      default() {
        return 119; // the body height margin + external margin
      },
    },
  },
  data() {
    return {
      scanMode: false,
      tabs: null,
      message: "",
      roomTypes: {
        ROOM_DELIVERY,
      },
      messages: [
        {
          sender: "User 1",
          message: "Hello, how are you?",
        },
        {
          sender: "User 2",
          message: "I'm good, thanks for asking! How about you?",
        },
      ],
    };
  },
  mounted() {
    this.tabs = "tab-" + this.$route.query.tab;
    this.loadStore(this.order.storeId);
    console.log(this.order);
  },
  computed: {
    ...mapGetters("stores", ["getStoreById"]),
    ...mapGetters("counters", ["getScannedItems", "getActiveScan"]),
    ...mapGetters("products", ["getStoreProductByProp"]),

    dispatchMode() {
      return ['Manager', 'Dispatcher'].includes(this.audiance)
    },
    selectedItemImage() {
      const activeScan = this.getActiveScan(this.order.uuid);
      if(activeScan) {
        const storeProduct = this.getStoreProductByProp("uuid", activeScan.productUuid);
        console.log(storeProduct);
        if (storeProduct && this.notEmptyArray(storeProduct.images)) {
          const image = this.getServerImage(
            `stores/_${this.getStore.uuid}/${storeProduct.images[0]}`
          );
          console.log(image);
          return image;
        }
      }

      return null;
    },

    getStore() {
      return this.getStoreById(this.order.storeId);
    },

    getCustomerAvatar() {
      return `${this.getApiBaseUrl}/files/avatar/${this.order?.customerUuid}`;
    },
    getDriverAvatar() {
      return `${this.getApiBaseUrl}/files/avatar/${this.order?.driverUuid}`;
    },
    getItems() {
      return this.order && this.notEmptyArray(this.order.items)
        ? this.order.items.map((m) => ({
            ...m,
            name: m.productName,
            nameAr: m.productNameAr,
          }))
        : [];
    },
    getCurrency() {
      if (
        this.order &&
        this.order.address &&
        this.order.address.store &&
        this.order.address.store.region
      ) {
        return this.order.address.store.region.currency;
      }
      return "YE";
    },
    tabParam() {},
    getAosCoordinates() {
      // this.order.storeId
      // const { activeAddressId: a } = this.$getUserInfo();
      // if (!a || !a.store) {
      //   return [];
      // }
      if (this.getStore) {
        return this.getStore.aosGeom.geometry.coordinates;
      }
      return null;
    },
    getOrderItem() {
      const activeScan = this.getActiveScan(this.order.uuid);
      return activeScan
        ? this.getItems.find((f) => f.productUuid === activeScan.productUuid)
        : null;
    },
    scanTimes() {
      const activeScan = this.getActiveScan(this.order.uuid);

      return activeScan
        ? activeScan.scans
        : 0;
    },

    orderScanComplete() {
      const orderScans = this.getScannedItems(this.order.uuid);
      const scanned = orderScans.map((m) => m.productUuid);
      console.log(scanned);
      return orderScans.every((f) => f.scans === f.quantity) &&
        this.getItems.every((f) => {
          const item = orderScans.find((s) => s.productUuid === f.productUuid);
          return item && item.scans === f.quantity;
        });
    },
    scanComplete() {
      return this.scanTimes === this.getOrderItem.quantity && this.scanMode;
    },
    notScanned() {
      return this.scanTimes === 0 && this.scanMode;
    },
    scanOverflow() {
      return this.scanTimes > this.getOrderItem.quantity;
    },
    scanNotCompleted() {
      return this.scanTimes > 0 && this.scanTimes < this.getOrderItem.quantity;
    },
    scanStatusColor() {
      return this.scanComplete ?
        'green lighten-5':
        this.scanNotCompleted ?
          'yellow lighten-4':
          this.scanOverflow ?
            'red lighten-5':
            '';
    },

  },
  methods: {
    ...mapActions("users", ["beepAlert"]),
    ...mapActions("stores", ["loadStore"]),
    ...mapActions("counters", ["scanItem", "resetItem", "selectItem", "clearScannedItems"]),
    ...mapActions("orders", ["confirmOrderItems", "collectOrderCash", "returnOrder"]),
    ...mapActions("ui", ["showDialog", "closeDialog"]),
    ...mapActions("auth", ["permitted"]),

    toggleScanMode() {
      this.scanMode = !this.scanMode;
      if (!this.scanMode) {
        this.barcode = null;
      }
    },
    confirmOrderScan() {
      this.confirmOrderItems({ storeId: this.order.storeId, orderUuid: this.order.uuid});
    },
    confirmCashCollection() {
      const dialog = {
          title: "Cash Collection Confirmation",
          text: `Are you sure you collected the payment for Order No. ${this.order.id}?`,
          actions: [
            {
              text: "Yes",
              click: () => {
                this.collectOrderCash({ storeId: this.order.storeId, orderUuid: this.order.uuid});
                this.closeDialog();
                this.$emit("order:dismiss");
              },
              color: "success",
            },
            {
              text: "No",
              click: () => this.closeDialog(),
              color: "primary",
            },
          ],
        };
      this.showDialog(dialog);
    },
    confirmOrderReturn() {
      const dialog = {
          component: AdminConfirmDialog,
          width: 300,
          params: {
            body: `Please confirm package return of order No. ${this.order.id}?`,
          },
          actions: [
            {
              text: "Yes",
              click: ({principal, credential}) => {
                this.permitted({principal, credential, permission: "Return.Order"}).then(() => {
                  this.returnOrder({ storeId: this.order.storeId, orderUuid: this.order.uuid});
                  this.$emit("order:dismiss");
                })
                this.closeDialog();
              },
              color: "primary",
              dark: true,
            },
            {
              text: "No",
              click: () => this.closeDialog(),
              color: "primary lighten-2",
            },
          ],
        };
      this.showDialog(dialog);
    },
    // item: StoreProduct
    itemScanned(barcode) {
      const item = this.getItems.find((f) => f.productBarcode === barcode);
      this.scanItem({orderUuid: this.order.uuid, item});
    },
    itemReset(item) {
      this.resetItem({orderUuid: this.order.uuid, item});
    },
    itemSelected(item) {
      console.log(item);
      this.selectItem({orderUuid: this.order.uuid, item});
    },
    orderScanCleared() {
      this.clearScannedOrder(this.order.uuid);
    },
    
    getMarkers(order) {
      const a = this.getAddress(order);
      const target = {
        icon: "mdi-home-map-marker",
        color: "white",
        status: order.customerName,
        class: this.getStatusTypeColor(order.activeTrackStatus) + " pin",
        latitude: a.latitude || 12.7533427,
        longitude: a.longitude || 44.8926315,
        target: true,
        type: "CUSTOMER",
        click: () => {},
      };

      const driver = {
        // icon: "mdi-moped",
        icon: "mdi-truck-fast-outline",
        color: "black",
        class: "amber circle",
        status: order.activeTrackStatus,
        latitude: order.latitude || 12.7543,
        longitude: order.longitude || 44.8955,
        type: "DRIVER",
        click: () => {},
      };

      const markers = [
        {
          icon: "mdi-headset",
          color: "#195567",
          class: "secondary pin2",
          status: "MAKZANI",
          latitude: 12.75896,
          longitude: 44.89213,
          type: "STORE",
          click: () => {},
        },
        { ...target },
        { ...driver },
      ];

      return markers;
    },

    getRoomUuid(uuId, sugment) {
      const segments = uuId.split("-");
      segments[3] = sugment;
      return segments.join("-");
    },

    callCustomer(phone) {
      this.$call(phone);
    },
  },
};
</script>

<template>
  <v-container>
    <v-form ref="form" v-model="valid" @submit.prevent>
      <p>{{ params.body }}</p>
      <v-row>
        <v-col>
          <v-text-field
            v-model="params.principal"
            :label="$t('login.usernameOrEmail')"
            name="login"
            prepend-icon="mdi-account-circle"
            type="text"
            dense
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="params.credential"
            id="password"
            :label="$t('user.password')"
            name="password"
            prepend-icon="mdi-lock"
            type="password"
            dense
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: "AdminConfirmDialog",
  model: {
    prop: "params",
    event: "input",
  },
  props: {
    params: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
};
</script>

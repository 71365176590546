<template>
  <div>
    <v-alert v-if="notEmptyArray(items) && notEmptyArray(itemsFeedback)" class="body-2 py-1 px-2  black--text" icon="mdi-alert-outline" type="warning" dense>
      Some items are not available now, or the available quanitity is not sufficient. Please review the messages associated with items.
    </v-alert>

    <v-simple-table id="order-items" dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th>Name</th>
            <th class="text-center">Quantity</th>
            <th class="text-right">Price</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in getItems" :key="i">
            <td>
              <div style="padding: 1px 0px 0px 2px;">{{ getLocale.lang === "ar" ? item.nameAr : item.name }}</div>
              <div v-if="!!item.itemsFeedback" class="red--text">
                {{
                  $t(item.itemsFeedback.message, {
                    available: item.itemsFeedback.attributes.available,
                  })
                }}
              </div>
            </td>
            <td v-if="!editable" class="text-center">{{ item.quantity }}</td>
            <td v-if="editable" class="text-right">
              <cart-buttons :item="item" :warning="!!item.itemsFeedback" />
            </td>
            <td :class="`text-right ${!!item.itemsFeedback ? 'red--text' : ''}`" style="min-width: 100px">
              {{ currency + " " + item.unitPrice * item.quantity }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import CartButtons from "@/views/orders/CartButtons";

export default {
  name: "OrderItems",
  components: { CartButtons },
  model: {
    prop: "items",
    event: "input",
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    itemsFeedback: {
      type: Array,
      default() {
        return [];
      },
    },
    editable: Boolean,
    currency: {
      type: String,
      default() {
        return "YE";
      },
    },
  },
  computed: {
    getItems() {
      let items = [];
      if (this.notEmptyArray(this.items) && this.notEmptyArray(this.itemsFeedback)) {
        items = this.items.map((m) => ({
          ...m,
          itemsFeedback: this.itemsFeedback.find((f) => f.resourceId === m.id),
        }));
      } else {
        items = [...this.items];
      }

      console.log(items)

      return items;
    }
  },
};
</script>

<style>
  #order-items th, #order-items td {
    padding: 0 2px;
  }

  #order-items th:not(:last-child), #order-items td:not(:last-child) {
    border-right: 1px dashed #eee;
  }
</style>
<template>
  <v-card flat>
    <div class="pa-2">
      <div><strong>Deliver To:</strong> {{ order.customerName }}</div>
      <div><strong>Address:</strong> {{ getAddressString(order) }}</div>
    </div>

    <v-simple-table dense class="grey lighten-4" light>
      <template v-slot:default>
        <tbody>
          <tr>
            <td>{{ $t("cart.itemsPrice") }}</td>
            <td class="text-right">
              {{
                currency +
                  " " +
                  (order.orderPrice - (order.deliveryPrice || 0))
              }}
            </td>
          </tr>
          <tr>
            <td :class="isFreeDelivery ? 'text-decoration-line-through' : ''">
              {{ $t("cart.deliveryFees") }}
            </td>
            <td
              :class="
                isFreeDelivery
                  ? 'text-decoration-line-through text-right'
                  : 'text-right'
              "
            >
              {{ currency + " " + (order.deliveryFees || 0) }}
            </td>
          </tr>
          <tr>
            <td class="font-weight-bold">
              {{ $t("cart.grandTotal") }}
            </td>
            <td class="font-weight-bold text-right">
              {{ currency + " " + order.orderPrice }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import mixin from "@/views/orders/orders.mixin";

export default {
  name: "OrderSummary",
  mixins: [mixin],
  model: {
    prop: "order",
    event: "input",
  },
  props: {
    order: Object,
    currency: {
      type: String,
      default() {
        return "YE";
      },
    },
  },
  computed: {
    isFreeDelivery() {
      return this.order.deliveryPrice === null;
    },
  },
};
</script>
